<template>
	<div>
		<div class="content">
			<div class="all">
				<div class="table" v-if="!dialogVisible">
					<!--搜索框-->
					<el-form
						ref="search"
						:inline="true"
						:model="search"
						size="small"
					>
						<el-form-item label="用户分类">
							<el-select
								v-model="search.userType"
								clearable
								filterable
								placeholder="请选择"
								size="mini"
								
							>
							<!-- @change="getList(1)" -->
								<el-option
									v-for="item in userTypes"
									:key="item.id"
									:label="item.name"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="任务类型">
							<el-select
								v-model="search.taskType"
								clearable
								filterable
								placeholder="请选择"
								size="mini"
							>
							@change="getList(1)"
								<el-option
									v-for="item in taskTypes"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item> -->

						<el-form-item label="状态">
							<el-select
								v-model="search.finishType"
								clearable
								filterable
								placeholder="请选择"
								size="mini"
							>
								<el-option
									v-for="item in statusList"
									:key="item.id"
									:label="item.label"
									:value="item.id"
								>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="安检员">
							<el-input
								v-model="search.inspectorName"
								size="mini"
								clearable
								placeholder="关键字"
								class="EquipmentMarginLeft"
							></el-input>
						</el-form-item>
						<el-form-item label="任务编号">
							<el-input
								v-model="search.taskCode"
								size="mini"
								clearable
								placeholder="关键字"
								class="EquipmentMarginLeft"
							></el-input>
						</el-form-item>
						<el-form-item>
							<div>
								<el-button
									type="primary"
									size="small"
									@click="getList"
									>查询</el-button
								>
								<el-button
									type="warning"
									size="small"
									@click="reset"
									>重置</el-button
								>
								<el-button
                    v-if="$anthButtons.getButtonAuth('fmrwdc')"
                    size="small"
									type="primary"
									@click="exportFile"
									>导出</el-button
								>
							</div>
						</el-form-item>
					</el-form>
					<div style="display: flex">
						<el-button
							type="text"
							style="
								border: 1px solid #1082ff;
								color: #1082ff;
								padding: 0 14px;
								height: 30px;
							"
							plain
							@click="addItem(1)"
							v-if="$anthButtons.getButtonAuth('addUnResident')" 
							size="small"
							>添加</el-button
						>
					</div>
					<!--数据表格-->
					<el-table
						ref="tableData"
						:data="tableData"
						style="width: 100%; margin: 15px 0"
						height="calc(100vh - 365px)"
						:stripe="true"
						
						:header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
					>
						<el-table-column
							type="index"
							label="序号"
							width="70"
						>
						</el-table-column>
						<el-table-column
							prop="taskCode"
							label="任务编号"
              width="120"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="userTypeName"
							label="用户分类"
							show-overflow-tooltip
              width="100"
						>
						</el-table-column>
						<!-- <el-table-column
							prop="taskType"
							label="任务类型"
							show-overflow-tooltip
              width="100"
						>
							<template slot-scope="{ row }">
								{{
									row.taskType == 1 ? "单次任务" : "周期任务"
								}}
							</template>
						</el-table-column> -->
						<el-table-column
							prop="templateName"
							label="采集模板"
							show-overflow-tooltip
							width="100"
						>
						</el-table-column>
						<el-table-column
							prop="inspectorNames"
							label="安检员"
							show-overflow-tooltip
						>
              <!-- <template slot-scope="{row}"> -->
                  <!-- <div  slot="reference" class="twoLines"> -->
					<!-- <el-tooltip placement="top-start"  :content='row.inspectorNames' effect="dark"> -->
						<!-- {{row.inspectorNames}} -->
                    <!-- <p>
                      {{fillterName(row.inspectorNames)[0]}}
					  <br/>
                    </p>
                    <p>
                      ({{fillterName(row.inspectorNames)[1]}}
                    </p> -->
					<!-- </el-tooltip> -->
                  <!-- </div> -->
              <!-- </template> -->
						</el-table-column>
						<el-table-column
							prop="houseCount"
							label="总户数"
							width="80"
						>
						</el-table-column>
						<el-table-column
							prop="completeCount"
							label="已检查数"
							width="80"
						></el-table-column>
						<el-table-column
							prop="doneCount"
							label="已完成数"
							width="80"
							show-overflow-tooltip
						>
						</el-table-column>
						<el-table-column
							prop="startTime"
							label="任务时间"
							show-overflow-tooltip
              width="200"
						>
							<template slot-scope="{ row }">
								{{
									row.startTime
										.split(" ")[0]
										.replaceAll("-", "/")
								}}-{{
									row.endTime
										.split(" ")[0]
										.replaceAll("-", "/")
								}}
							</template>
						</el-table-column>
						<el-table-column
							prop="createTime"
							label="添加时间"
							show-overflow-tooltip
							width="150"
						>
						</el-table-column>
						<el-table-column
							prop="finishType"
							label="状态"
							show-overflow-tooltip
							width="100"
						>
							<template slot-scope="{ row }">
								{{
									row.finishType == 1
										? "未开始"
										: row.finishType == 2
										? "进行中"
										: "已结束"
								}}
							</template>
						</el-table-column>
						<el-table-column
							prop="operation"
							label="操作"
							width="233px"
						>
							<template slot-scope="{ row }">
								<el-button
                    v-if="$anthButtons.getButtonAuth('fjmck')"
                    type="primary"
									size="small"
									@click="addItem(3, row)"
									>查看</el-button
								>
								<!-- 未开始和进行中的任务可编辑，可编辑的内容不同，点击分别打开不同的编辑页 -->
								<el-button
									type="primary"
									size="small"
									@click="addItem(2, row)"
									v-if="(row.finishType == 1 || row.finishType == 2)&& $anthButtons.getButtonAuth('fjmbj')"
									>编辑</el-button
								>
								<!-- v-if="row.status == '已登记'" -->
								<!-- 仅有未开始的单次任务可删除，点击进行提示 -->
								<!-- <el-button
									type="danger"
									size="mini"
									v-if="
										row.finishType == 1 && row.taskType == 1 && $anthButtons.getButtonAuth('fmrwsc')
									"
									style="background-color: #ee2d2d"
									@click="delItem(row)"
									>删除</el-button
								> -->
							</template>
						</el-table-column>
					</el-table>
					<div class="pageBox">
						<el-pagination
							:current-page="search.current"
							:background="true"
							:page-sizes="[30, 50, 100]"
							:page-size="search.size"
							layout="total, prev, pager, next, sizes, jumper"
							:total="total"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
						>
						</el-pagination>
					</div>
				</div>
				<!-- <div v-show="dialogVisible">
					<unAdd
						:titleStatus="titleStatus"
						@close="close"
						ref="desList"
					></unAdd>
				</div> -->
			</div>
		</div>
		<el-dialog title="导出中..." :visible.sync="deriveVisible" width="30%">
			<el-table v-loading="loading" empty-text="导出中"> </el-table>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
			</span>
		</el-dialog>
		<!-- 编辑进行中的任务 -->
		<el-dialog
			:visible.sync="editConductVisible"
			:close-on-click-modal="false"
			width="30%"
		>
			<el-form
				ref="editConductform"
				:model="editConductform"
				label-width="80px"
				:rules="editConductRules"
			>
				<el-form-item label="安检员" prop="inspectorIds">
					<el-select
						style="width: 400px"
						v-model="editConductform.inspectorIds"
						placeholder="请选择"
						clearable
						multiple
						filterable
					>
						<el-option
							v-for="item in checkerList"
							:key="item.id"
							:label="item.realname"
							:value="parseInt(item.id)"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item
					label="任务时间"
					prop="startTime"
					v-if="editConductform.taskType == 1"
				>
					<el-date-picker
						style="width: 400px"
						v-model="editConductTimes"
						type="daterange"
						range-separator="至"
						placeholder="请选择时间段"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="setDate"
						value-format="yyyy-MM-dd"
						:picker-options="pickerOptions"
					>
					</el-date-picker>
					<el-date-picker
						v-model="value1"	
						type="date"
						style="width: 400px"
						value-format="yyyy-MM-dd"
						:picker-options="pickerOptions"
						placeholder="选择日期">
					</el-date-picker>
				</el-form-item> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editConductVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveData">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
 
<script>
	import unAdd from "./assembly/unAdd";
	import { taskPageList, taskDeleteById } from "@/RequestPort/selectQuery/select";
	import { exportMethod } from "../../common/js/exportExcel";
	import { userTypeDropDown } from "@/RequestPort/induction";
	import {
		aaumTask_selectTaskById,
		AaumArchive_inspectorDropDown,
		aaumTask_taskUpdate,
		aaumTask_exportTaskExcel,
	} from "../../RequestPort/securityTask/unResident";
	export default {
		name: "unResident",
		data() {
			let _this = this;
			return {
				// 搜索条件
				search: {
					current: 1,
					size: 100,
				},
				// findObj: {},
				// 选择时间
				value1:'',
				// 总条数
				total: 0,
				// 用户分类
				userTypes: [],
				// 任务类型
				taskTypes: [
					{ label: "周期任务", id: 2 },
					{ label: "单次任务", id: 1 },
				],
				// checkerList: [],
				// 状态
				statusList: [
					{ label: "未开始", id: 1 },
					{ label: "进行中", id: 2 },
					{ label: "已结束", id: 3 },
				],
				dialogVisible: false,
				editConductform: {},
				checkerList: [],
				editConductRules: {
					inspectorIds: [
						{
							required: true,
							message: "请选择安检员",
							trigger: "change",
						},
					],
					startTime: [
						{
							required: true,
							trigger: "change",
							message: "请选择任务时间",
						},
					],
				},

				// 非居民任务
				tableData: [],
				titleStatus: 1, // 1添加2编辑3查看
				editConductVisible: false,
				// 导出
				deriveVisible: false,
				loading: true,
				editConductTimes: [],
        pickerOptions: {
		// 			onPick({ maxDate, minDate }) {
		// 				_this.$nextTick(() => {
		// 					const end = new Date();
		// 					end.setTime(end.getTime());
		// 					if (
		// 						minDate &&
		// 						maxDate &&
		// 						Date.parse(maxDate) >
		// 							new Date().getTime() - 24 * 60 * 60 * 1000
		// 					) {
		// 						this.$message.error(
		// 							"结束时间仅可选择今天及之后的时间"
		// 						);
        //       }

        //   })
        //   }
		disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7; 
                }
		  }
      }
  },
  watch: {
    '$store.getters.deriveVisible'(val) {
        this.deriveVisible = val
    },
  },
  mounted() {
    // 获取列表
    this.getList()
    // 用户分类
    this.getuserTypeDropDown()
    AaumArchive_inspectorDropDown({}).then(res=>{
        this.checkerList = res.data;
      })
  },
  components: { unAdd },
  methods: {
    fillterName(name){
      return name.split('(')
    },
    /***
     * 用户分类
     */
    getuserTypeDropDown(){
      userTypeDropDown().then(res => {
        this.userTypes = res.data
      })
    },
    
    /**
     * 获取列表数据
     */
    getList(val){
      if(val){
        this.search.current = 1
      }
      taskPageList(this.search).then(res=>{
        this.tableData = res.data.records
        this.total =  res.data.total
      })
    },
    // pickDate (time) {
    //   console.log(time,6546)
    // },
    /**
     * 添加页面
     */
    addItem(val,row) {
      if(val == 2&&row.finishType==2){
        this.editConductVisible = true
        aaumTask_selectTaskById({taskId:row.id}).then(res=>{
          this.editConductform = res.data
          this.editConductTimes = [res.data.startTime,res.data.endTime]
		  this.value1 = res.data.endTime
        })
      }else{
		let roleIds = localStorage.getItem("roleIds").split(',')
        let index = roleIds.findIndex(el => el == '1')
        if(index >= 0) {
          this.$message.info('系统管理员禁止添加！')
          return
        }
        this.titleStatus = val;
		let getDateNumber
        // this.dialogVisible = true;
        if(this.titleStatus==3||(this.titleStatus == 2&&row.finishType==1)){
        //   this.$refs.desList.getDesc(row.id);
		getDateNumber = row.id
        }
        if(this.titleStatus==1){
        //   this.$refs.desList.getDesc();
		getDateNumber = ''
        }
		let chakan = this.$router.resolve({
			path: '/home/resident/unAdd',      //跳转目标窗口的地址
			query: { id: getDateNumber ,titleStatus:this.titleStatus} 
		})
		window.open(chakan.href);
      }
      
      
    },
    setDate(val){
      this.editConductform.startTime = this.editConductTimes[0]
      this.editConductform.endTime = this.editConductTimes[1]
    },
    saveData(){
      this.$refs["editConductform"].validate((valid) => {
        if (valid) {
          let data = {id:this.editConductform.id,inspectorIds:this.editConductform.inspectorIds,startTime:this.editConductform.startTime,endTime:this.value1,userIds:this.editConductform.userIds,
          userType:this.editConductform.userType}
          aaumTask_taskUpdate(data).then(res=>{
            if(res.code==200){
              this.$message.success(res.data)
              this.getList(1)
              this.closeEdit()
            }else{
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    closeEdit(){
      this.editConductform = {}
      this.editConductVisible = false;
      this.editConductTimes = []
      if(this.$refs.editConductform){
        this.$refs.editConductform.resetFields()
      }
    },
    /**
     * 更换条数
     */
    handleSizeChange(val){
      this.search.current = 1
      this.search.size = val
      this.getList()
    },
    /**
     * 更换页码
     */
    handleCurrentChange(val){
      this.search.current = val
      this.getList()
    },
    /**
     * 获取列表数据
     */
    // loadList(){
    //   console.log('获取数据',this.search)
    // },
    /**
     * 删除功能
     */
    delItem(row) {
      this.$confirm("是否确认删除", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        taskDeleteById({taskId:row.id}).then(res => {
          this.getList()
          this.$message.success("删除成功");
        })
        
      })
    },
    // inquire() {
    //   this.search.current = 1;
    //   this.objCopy();
    //   // this.getTaskList()
    // },
    // objCopy() {
    //   for (let key in this.search) {
    //     this.findObj[key] = this.search[key];
    //   }
    // },
    /**
     * 重置
     */
    reset() {
      this.search = {
        current: 1,
        size: 100,
      };
      this.getList()
    },
    /**
     * 导出
     */
    exportFile() {
      this.deriveVisible = true
	  this.$store.commit('setderiveVisible',true)
      aaumTask_exportTaskExcel(this.search).then(res=>{
		this.deriveVisible = false
        exportMethod(res,'非居民任务')
        this.$store.commit('setCancelTokenList',null)
      })
      
      
    //  console.log(localStorage.getItem('cancelToken')[0]('请求取消'))
    },
    cancel(){
      this.deriveVisible = false
	  this.$store.commit('setderiveVisible',false)
      // if(!this.editConductVisible){
        // this.$store.getters.cancelTokenList('取消接口')
        window.stop()
      // }
    },
    close(val) {
      this.dialogVisible = !this.dialogVisible; //监听值发生变化就关闭它
      if(!this.dialogVisible){
        this.getList(1)
      }
    },
  },
}
</script>
 
<style scoped lang="less">
	.table {
		padding: 10px;
		border-radius: 5px;
		margin: 10px;
		background-color: #ffffff;
	}
	.all {
		background-color: #f3f4f8;
		height: auto;
	}
	.content {
		background: #f3f4f8;
		width: 100%;
		border-radius: 6px;
		height: calc(100vh - 150px);
	}
</style>